import VueRouter from "vue-router";
const MainLayout = () =>
  import(
    /* webpackChunkName: "login/main" */
    "../Pages/MainLayout"
  );
const LoginForm = () =>
  import(
    /* webpackChunkName: "login/login" */
    "../Pages/LoginForm"
  );
const ResetPassword = () =>
  import(
    /* webpackChunkName: "login/password" */
    "../Pages/ResetPassword"
  );

const router = new VueRouter({
  mode: "history",
  routes: [
    {
      path: "/login",
      component: MainLayout,
      props: true,
      children: [
        {
          path: "/login",
          component: LoginForm,
          name: "login",
          props: true,
        },
        // {
        //   path: "/password/reset/:token?/:email?",
        //   component: ResetPassword,
        //   name: "forgotPassword",
        //   props: true,
        // },
      ],
    },
    {
      path: "",
      redirect: "/login",
    },
  ],
});
export default router;
