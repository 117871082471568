import {
  UilAngleDown,
  UilAngleLeft,
  UilAngleRight,
  UilTimesSquare,
  UilTimes,
  UilBrightnessEmpty,
  UilCheckCircle,
} from "@iconscout/vue-unicons";

import { UitSquareFull, UitCheckSquare } from "@iconscout/vue-unicons-thinline";

const kioskTheme = {
  options: {
    customProperties: true,
    customVariables: true,
  },
  themes: {
    light: {
      accent: "#3D4C67",
      anchor: "#fff",
      appointmentDetailsBody: "#313130",
      appointmentDetailsContent: "#1A1919",
      appointmentDetailsFooter: "#1A1919",
      appointmentDetailsHeaer: "#1A1919",
      appointmentDetailsLabel: "#B9C9DB",
      buttonDisabled: "#878889",
      buttonPrimary: "#3d4c67",
      buttonPrimaryDarken: "#1B2330",
      calendarAccentColor: "#3d4c67",
      calendarButtonColor: "#5B83C4",
      calendarButtonFontColor: "#7B8794",
      calendarPickerBorder: "#3d4c67",
      cardBackgroundColor: "#1A1919",
      cardBackgroundColor: "#242424",
      cardHeaderBackgroundColor: "#313130",
      closeButton: "#fff",
      closeButtonIcon: "#ffffff",
      defaultSubtitleColor: "#fff",
      defaultText: "#ffffff",
      displayMessageText: "#B9C9DB",
      emptyMessageBackgroundColor: "#242424",
      error: "#ff2b2b",
      headingOne: "#fff",
      inactive: "#757E8B",
      inputBorderColor: "#5e656f", //this is equal to #757e8b with opacity 0.8 on black background
      inputTextColor: "#ffffff",
      l6modalTextColor: "#fff",
      listItemColor: "#fff",
      listItemHoverColor: "#757E8B",
      primary: "#3D4C67",
      primaryLabel: "#fff",
      radioButtonColor: "#1b2330",
      reservationBackground: "#1A1919",
      secondary: "#F5F7FA",
      secondaryLabel: "#fff",
      selectInputBackground: "#1A1919",
      selectInputBorderColor: "#5e656f", //this is equal to #757e8b with opacity 0.8 on black background
      selectInputBorderColorDark: "#fff", //this is equal to #757e8b with opacity 0.8 on black background
      selectInputTextColor: "#fff",
      selectLabel: "#fff",
      selectMenuBackgroundColor: "#1A1919",
      stepperLabel: "#fff",
      success: "#3d931f",
      textareaBackgroundColor: "#1A1919",
      textareaBorderColor: "#5e656f", //this is equal to #757e8b with opacity 0.8 on black background
      textareaLabel: "#fff",
      title: "#ffffff",
      warning: "#CE3333",
    },
  },
};
const kioskIcons = {
  values: {
    dropdown: { component: UilAngleDown },
    leftArrow: {
      component: UilAngleLeft,
    },
    rightArrow: {
      component: UilAngleRight,
    },
    close: {
      component: UilTimesSquare,
    },
    clear: {
      component: UilTimes,
    },
    delete: {
      component: UilTimes,
    },
    checkboxOff: {
      component: UitSquareFull,
    },
    checkboxOn: {
      component: UitCheckSquare,
    },
    complete: {
      component: UilCheckCircle,
    },
    edit: {
      component: UilBrightnessEmpty,
    },
  },
};
const kioskBreakpoints = {
  mobileBreakpoint: 833,

  thresholds: {
    xs: 340,
    sm: 540,
    md: 833,
    lg: 1280,
  },
  scrollBarWidth: 24,
};
export { kioskTheme, kioskIcons, kioskBreakpoints };
