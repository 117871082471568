import Vue from "vue";
import Vuetify from "vuetify";
import "vuetify/dist/vuetify.min.css";

import {
  kioskTheme,
  kioskIcons,
  kioskBreakpoints,
} from "../../../sharedComponents/themes/kioskTheme";
Vue.use(Vuetify);

export default new Vuetify({
  theme: kioskTheme,
  icons: kioskIcons,
  breakpoint: kioskBreakpoints,
  customVariables: true
});
