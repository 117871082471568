<template>
  <v-overlay class="loading-spinner text-center" :value="loading">
    <v-progress-circular
      indeterminate
      color="#3d4c67"
      :width="5"
      :size="50"
      class="my-auto mx-auto"
    />
  </v-overlay>
</template>
<script>
export default {
  name: "loading-spinner",
  props: {
    loading: Boolean,
  },
};
</script>

<style lang="scss">
.loading-card {
  padding: 30px;
  justify-content: space-between;
  display: flex;
  flex-direction: column;
  border-radius: 8px !important;
}
</style>
