<template>
  <div id="login__main" class="content-wrap">
    <div id="login-content-wrapper">
      <router-view :logoUrl="logoUrl" />
    </div>
    <loading-spinner :loading="isLoading" />
  </div>
</template>

<script>
import LoadingSpinner from "../../sharedComponents/LoadingSpinner.vue";
export default {
  name: "l6-login",
  props: ["logoUrl", "formToken"],
  components: {
    LoadingSpinner,
  },
  data() {
    return {
      isLoading: false,
    };
  },
  methods: {
    handleLoading(value) {
      this.loading = value;
    },
  },
};
</script>

<style  lang="scss">
  
* {
  font-family: Roboto !important;
}
#login__main {
  display: grid;
  // place-items: center;
  background-image: url("/images/clouds.svg");
  height: 100%;
  min-height: -webkit-fill-available;
  max-height: 100vh;
  min-width: 100%;
  background-size: cover;
  background-position: top center;
  color: #fff;
  overflow-y: auto;
  overflow-x: hidden;
}
#login-content-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  display: inherit;
  place-items: inherit;
}

.loading-spinner {
  z-index: 9999 !important;
}
</style>
